<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="支店名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="address" label="住所" prop="address">
        <a-input
          v-model="form.address"
          @blur="
            () => {
              $refs.address.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="備考" ref="comment">
        <a-input v-model="form.comment" type="textarea" />
      </a-form-model-item>
      <a-form-model-item ref="alcohol_check_type" label="アルコールチェックの使用" prop="alcohol_check_type">
        <a-radio-group v-model="form.alcohol_check_type">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">ドライバーが点呼完了処理</a-radio>
          <a-radio :value="2">管理者が点呼完了処理</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="alcohol_check_default_allow_ride" label="[デフォルト値]アルコールチェック時の乗車可否" prop="alcohol_check_default_allow_ride">
        <a-radio-group v-model="form.alcohol_check_default_allow_ride">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">許可</a-radio>
          <a-radio :value="2">不許可</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="alcohol_check_default_alcohol_detector" label="[デフォルト値]アルコールチェック時のアルコール検知器" prop="alcohol_check_default_alcohol_detector">
        <a-radio-group v-model="form.alcohol_check_default_alcohol_detector">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">有り</a-radio>
          <a-radio :value="2">無し</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="alcohol_check_default_drinking" label="[デフォルト値]アルコールチェック時の酒気帯び" prop="alcohol_check_default_drinking">
        <a-radio-group v-model="form.alcohol_check_default_drinking">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">有り</a-radio>
          <a-radio :value="2">無し</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="alcohol_check_default_roll_call_type" label="[デフォルト値]アルコールチェックの点呼方法" prop="alcohol_check_default_roll_call_type">
        <a-radio-group v-model="form.alcohol_check_default_roll_call_type">
          <a-radio :value="0">未選択</a-radio>
          <a-radio :value="1">対面</a-radio>
          <a-radio :value="2">ビデオ通話</a-radio>
          <a-radio :value="3">電話</a-radio>
          <a-radio :value="4">その他</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="plusFlag === 1" ref="use_inspection_flag" label="運行前点検の使用" prop="use_inspection_flag">
        <a-radio-group v-model="form.use_inspection_flag">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">
          {{ submitString }}
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>

import Vue from 'vue'
import { objectData } from '@/services/object'

export default {
  name: 'FormCompany',
  data() {
    return {
      loading: true,
      submitType: this.$route.meta.submitType,
      submitString: this.$route.meta.submitString,
      backPath: '/company',
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      branch: Object.assign({}, objectData.branch),
      form: {
        name: '',
        address: '',
        comment: '',
        alcohol_check_type: 0,
        alcohol_check_default_allow_ride: 0,
        alcohol_check_default_alcohol_detector: 0,
        alcohol_check_default_drinking: 0,
        alcohol_check_default_roll_call_type: 0,
        use_inspection_flag: 0,
      },
      rules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        alcohol_check_type: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        alcohol_check_default_allow_ride: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        alcohol_check_default_alcohol_detector: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        alcohol_check_default_drinking: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        alcohol_check_default_roll_call_type: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
        use_inspection_flag: [
          { type: 'number', message: '整数を入力してください', trigger: 'blur' },
        ],
      },
      plusFlag: 0,
    }
  },
  watch: {
    branch: function(val) {
      this.form.name = val.name
      this.form.address = val.address
      this.form.comment = val.comment
      this.form.alcohol_check_type = val.alcohol_check_type
      this.form.alcohol_check_default_allow_ride = val.alcohol_check_default_allow_ride
      this.form.alcohol_check_default_alcohol_detector = val.alcohol_check_default_alcohol_detector
      this.form.alcohol_check_default_drinking = val.alcohol_check_default_drinking
      this.form.alcohol_check_default_roll_call_type = val.alcohol_check_default_roll_call_type
      this.form.use_inspection_flag = val.use_inspection_flag
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'branches', this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'branches/' + _this.$route.params.id, this.form)
            send.then(response => {
              console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push('/branches/' + _this.$route.params.id)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: '権限がありません。',
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created() {
    const userResult = Vue.prototype.$api.send('get', 'user')
    userResult.then(response => {
      this.plusFlag = response.plus_flag
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザー情報の取得に失敗しました。',
        })
      })
    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'branches/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.branch = response
        this.backPath = '/branches/' + this.$route.params.id
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 支店の取得に失敗しました。',
          })
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
}
</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
